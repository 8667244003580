
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {BoostedDappCollection} from '@/model/collection/BoostedDappCollection'
import {FilterBoostedDappSchema} from '@/schema/resource/BoostedDapp/FilterBoostedDappSchema'

@Component
export default class FilterBoostedDapp extends Vue {
  @Prop({type: Object, required: true}) collection!: BoostedDappCollection

  schema = new FilterBoostedDappSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionDapp.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.collection.setCurrentPage(0)
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
