/**
 * BoostedDappCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {DappCollection} from '@/model/collection/DappCollection'

/* TODO: review generated class */
@HttpExclude()
export class BoostedDappCollection extends ExpansibleCollection<BoostedDapp> {
  constructor() {
    super(BoostedDapp)
  }

  resource?: IBoostedDappCollectionResourcesHolder

  @RequestExpose() minOrder: number | null = null
  @RequestExpose() maxOrder: number | null = null

  queryAsPage() {
    return this.listBoostedDapp()
  }

  async listBoostedDapp() {
    return await Request.get(`/client/boosted-dapp`, {
      params: this.params,
    })
      .name('listBoostedDapp')
      .as(this)
      .getResponse()
  }

  async listExportBoostedDapp() {
    return await Request.get(`/client/boosted-dapp/export`, {
      params: this.params,
    })
      .name('listExportBoostedDapp')
      .as(this)
      .getResponse()
  }
}

export interface IBoostedDappCollectionResourcesHolder {
  collectionDapp: DappCollection
}
