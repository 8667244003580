









































































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterBoostedDapp from '@/components/filters/FilterBoostedDapp.vue'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {BoostedDappCollection} from '@/model/collection/BoostedDappCollection'
import {ListBoostedDappSchema} from '@/schema/resource/BoostedDapp/ListBoostedDappSchema'
import {ExportBoostedDappSchema} from '@/schema/resource/BoostedDapp/ExportBoostedDappSchema'

@Component({
  components: {FilterToggle, FilterBoostedDapp},
})
export default class ListBoostedDappView extends Mixins(MixinRouteMatch) {
  schema = new ListBoostedDappSchema()
  collection = new BoostedDappCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }

    this.collection.setOrderBy('order')
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: BoostedDapp) {
    this.$nav.pushByName('editBoostedDapp', item.idDappFk)
  }

  async removeItem(item: BoostedDapp) {
    await this.$dialog.remove(item.dapp)
    await item.removeBoostedDapp()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new BoostedDappCollection().clearFilters().addFilter(params)

    await coll.listExportBoostedDapp()
    this.$xlsx.downloadFromSchema(coll.items, new ExportBoostedDappSchema())
  }

  async changeOrder(item: BoostedDapp, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
