/**
 * Export Schema of BoostedDapp
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {BoostedDapp} from '@/model/resource/BoostedDapp'

/* TODO: review generated schema */
export class ExportBoostedDappSchema extends DefaultSchema {
  readonly name = 'ExportBoostedDapp'

  readonly fieldSet: FieldSet<BoostedDapp> = {
    dapp: schema => schema.model.dapp?.$tag ?? null,
    order: schema => schema.model.order,
  }
}
